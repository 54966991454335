body {
    width: 100vw;
}

@media (max-width: 1000px) {
    .hide {
        display: none;
    }

    .home_notice {
        display: none;
    }

    .home_des {
        width: 100%;
    }

    .help_desk {
        width: 100vw;
    }

    .link {
        width: 100vw;
    }

    .link_sub {
        text-align: left;
    }

    .address {
        width: 100vw;
    }

    .footer_img {
        width: 50vw;
        padding: 10px 0px;
    }
}

@media (min-width:1000px) {
    .seek {
        display: none;
    }

    .home_des {
        width: 60%;
    }

    .help_desk {
        width: 25%;
    }

    .link {
        width: 25%;
    }

    .link_sub {
        text-align: center;
    }

    .help_sub {
        justify-content: space-between;
    }

    .help_sub2 {
        text-align: right;
    }

    .address {
        width: 25%;
    }

    .footer_img {
        width: 13%;
    }
}

body::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
    color: #00203f;
}

.rightcard {
    height: 90vh;
    width: 90vw;
    padding: 10px 20px;
    margin: 0px auto;
}

.leftcard {
    height: 90vh;
    width: 90vw;
    padding: 30px 20px;
    margin: 0px auto;
}

.mara {
    height: 60%;
    width: 40%;
    border-radius: 5px;
}

.accomplishment {
    margin: 15px;
}

.fitter {
    max-height: fit-content;
}

.scroll::-webkit-scrollbar {
    display: none;
}

#construction {
    height: max-content;
    width: 100%;
    margin: auto;
}

#construction>h5 {
    font-family: 'Kanit', sans-serif;
    font-size: 2dvw;
    color: white;
}

#construction p {
    color: rgb(173, 239, 209);
}

#st {
    transform: rotateY(90deg);
}

.carousel-item img {
    width: 100vw;
}

.texta h1 {
    font-family: Kanit, sans-serif;
    color: white;

}

.fa-icon {
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.fa-div {
    display: flex;
    width: 50%;
    margin: 10px 0px;
}

.fa-div p {
    margin: 10px;
}

.you {
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 10px;
}

.you h4 {
    font-family: Kanit, sans-serif;
    color: black;
    font-size: 40px;
    margin: 20px 0px;
}

.carousel-caption {
    text-align: left;
    font-family: Yeseva One, cursive;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: example 1000ms cubic-bezier(0.7, 0.39, 0.09, 0.29);
    top: 1.25rem;
    bottom: 1.25rem;
}

@keyframes example {
    0% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(0%);
    }
}

.home_notic::-webkit-scrollbar {
    display: none;
}

.home_notice {
    width: 30%;
    background-color: #F1E1AD;
    text-align: center;
    border-radius: 10px;
    padding: 15px 0px;
}

.load {
    width: 85%;
}

[data-carusal-text] {
    font-size: 3.6vw;
}

.footer_main {
    display: flex;
    justify-content: center;
    background-color: #F1E1AD;
    flex-flow: wrap;
}

.caru {
    width: 700vw;
}

.car-image {
    height: 70vh;
}

@media(max-width:500px) {
    .car-image {
        height: 30vh;
    }

    .help_sub {
        text-align: center;
    }

    .help_sub1 {
        width: 100%;
    }

    .help_sub2 {
        width: 100%;
    }

    .link_sub {
        text-align: center;
    }

    .address>p {
        text-align: center;
    }

    .decoration-top-rebbon {
        height: 20dvh;
        width: 100dvw;
    }

    .decoration-top-rebbon img {
        height: 20dvh;
    }

    .decoration-top-rebbon h1 {
        /* border: 2px solid red; */
        font-size: 3em;
        font-family: 'Kanit', sans-serif;
    }

    [data-about]>p {
        color: #ADEFD1FF;
    }

    [data-about-big] {
        display: none;
    }

    [data-lab-big] {
        display: none;
    }

    [data-lab-small] {
        width: 100%;
    }

    .texta {
        width: 100%;
    }

    [data-classroom-feature] h5 {
        color: white;
        font-family: 'Kanit', sans-serif;
    }

    [data-mtech] {
        margin: 20px 0;
    }

    [data-mtech-upper] {
        flex-flow: wrap;
    }

    [data-course-img-big] {
        display: none;
    }

    [data-course-img-small] {
        width: 100%;
    }

    [data-info-big] {
        display: none;
    }

    [data-course-des] {
        border-radius: 10px;
        width: 100%;
    }

    [data-course-faculty] {
        width: 100dvw;
    }

    [data-youtube] {
        width: 100%;
    }

    [data-faculty-des] {
        display: none;
    }

    [data-frame] {
        width: 100%;
        border-radius: 10px;
    }

    [data-short] {
        width: 100%;
    }

    [data-date-big] {
        display: none;
    }

}

@media (min-width:500px) {
    .decoration-top-rebbon {
        height: 30dvh;
        width: 100dvw;
    }

    .decoration-top-rebbon img {
        height: 30dvh;
    }

    .decoration-top-rebbon h1 {
        /* border: 2px solid red; */
        font-size: 80px;
        font-family: 'Kanit', sans-serif;
    }

    [data-about] {
        width: 43vw;
    }

    [data-about-small] {
        display: none;
    }

    [data-lab-small] {
        display: none;
    }

    [data-lab-big] {
        width: 50vw;
    }

    .texta {
        width: 30vw;
    }

    [data-classroom-feature] {
        width: 20dvw;
    }

    [data-mtech] {
        width: 28rem;
    }

    [data-course-img-small] {
        display: none;
    }

    [data-control] {
        width: 50%;
    }

    [data-course-img-big] {
        width: 32%;
    }

    [data-info-small] {
        display: none;
    }

    [data-course-des] {
        width: 75%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    [data-youtube] {
        width: 65%;
        height: 70vh;
    }

    [data-youtube]>iframe {
        height: 100%;
    }

    .you {
        width: 30%;
    }

    [data-short] {
        display: none;
    }

    [data-faculty-des] {
        display: flex;
    }

    [data-frame] {
        width: 30%;
        border: 2px solid white;
    }

    [data-date-small] {
        display: none;
    }

    [data-date-big] {
        width: 30vw;
    }

    [data-alu-full] {
        width: 50vw;
    }

}


.exiter {
    display: flex;
}

.exiter>p {
    margin: auto 0px;
}


[data-lab-design] {
    height: max-content;
}

.decoration-top-rebbon h1 {
    font-family: 'Kanit', sans-serif;
}

.decoration-top-rebbon img {
    opacity: 30%;
}

[data-rebbon-text] {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collapse>li {
    list-style: circle;
}

[data-about-media] {
    flex-flow: wrap;
}